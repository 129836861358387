@import 'utilities.scss';

.pending-request__form {
  .field-wrapper {
    input {
      margin-top: 0.25rem;
    }

    .has-error {
      background-color: #fbe7ee;
      border-color: #e41c23;
    }

    .error-field-area {
      color: #e41c23;
      font-size: 0.75rem;
      margin: 0.25rem 0 0 0;
    }
  }
  .assistant-row {
    position: absolute;
    bottom: 130px;

    .reserved-section {
      font-size: 20px;
      font-weight: 600;
    }

    .assistant-section-wrapper {
      display: flex;
      align-items: center;

      #request-assistant-checkbox {
        border-radius: 4px;
        width: 2em;
        height: 2em;
        -webkit-appearance: none;

        &:checked {
          border-color: transparent;
          background: #1572c5;
          position: relative;

          &::after {
            position: absolute;
            left: 50%;
            top: 55%;
            transform: translate(-50%, -50%);
            content: url('../../assets/icons/check/check.svg');
          }
        }
      }

      .request-assistant-label {
        margin-left: 10px;
      }
    }
  }
}
