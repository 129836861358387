@import '../../utilities';

#spouse-information-form {
  .dsc-alert {
    margin-bottom: 1rem;
  }

  .subtitle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.125rem;

    p {
      padding-left: 0;
      font-size: 1.125rem;
    }
  }

  .spouse-info-review {
    margin-top: 2rem;
  }

  .cancel-btn {
    text-align: center;
  }

  .name-row {
    margin-bottom: 1rem;
  }

  .name-label {
    margin-bottom: 0.5rem;
  }

  .label-info {
    color: $grey600;
  }

  .label-info,
  .label-value {
    line-height: $lh-paragraph;
  }

  .info-SRRSP {
    margin-bottom: 0.5rem;
    background-color: $blue075;
    border-radius: 0.25rem;
    padding: 1rem;

    div {
      padding: 0;
    }

    p {
      font-size: 1rem;
      color: $navy600;
      margin-top: 0.25rem;
    }

    svg {
      display: block;
      margin: auto;
      margin-top: 0.5rem;
    }
  }
}
