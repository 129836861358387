@import 'utilities.scss';

#beneficiary_form {
  h1.title {
    padding-bottom: 0.5rem;
  }

  .fields_accounts {
    margin-bottom: $s-medium;
  }

  .beneficiary_account_label {
    .row {
      padding-bottom: 0.5rem;
    }

    &:last-child {
      .row {
        padding-bottom: 0;
      }
    }

    .dsc-icon {
      margin-right: 0.5rem;
    }

    span {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .beneficiary-text-legal {
    display: inline-flex;
    align-items: baseline;
    margin-top: -1rem;

    svg {
      overflow: visible;
      margin-right: 0.5rem;
    }

    .product-form-input__help.legal {
      margin: 0;
      color: $cherry;
      font-size: 0.875rem;
      margin-top: 0.5rem;
      font-weight: 500;

      a.dsc-link {
        color: $cherry;
      }
    }
  }
}

.beneficiary-modal-select {
  max-height: 165px !important;
  min-height: 0 !important;
}

.modal-form-container {
  @media (min-width: $bao_breakpoint_small) {
    max-width: 616px;
  }
}

.percent-input {
  .dsc-input__container {
    .dsc-input {
      text-align: right;
      padding-right: 54px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .dsc-input__icon {
      border-left: solid 1px #b7b9be;
      background-color: #eeeeee;

      .dsc-icon {
        color: black;
        height: 24px;
        width: 16px;
      }
    }
  }
}

.table-grid-padding {
  padding: 6px 12px;
}

.sm-table-grid-padding {
  padding: 6px 0;
  @media (min-width: $bao_breakpoint_small) {
    padding: 6px 12px;
  }
}

.mc-w-100 {
  width: 100% !important;
}

.relationship-text {
  font-size: 14px !important;
}

.select-text-on-error {
  padding-bottom: 25px;
}

.contribution-error-red {
  color: #c00011;
}

.back-button svg {
  width: 1.5rem;
  height: 1.5rem;
}
