@import 'utilities.scss';

.specific-options__form {
  .dsc-select,
  .dsc-input {
    width: 60%;
    @media (max-width: $bao_breakpoint_small) {
      width: 100%;
    }
  }
  .subTitle {
    @extend .sub_title_normale;
  }
}
