@import 'utilities.scss';

.client-redirect__container {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 70%;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(43, 45, 49, 0.25);
  background-color: white;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;

  .page-title {
    margin: 2.5rem 0.5rem 0.5rem 0.5rem;
  }

  .row {
    margin-bottom: 1.5rem;

    .dsc-heading.question-title {
      margin: 1rem 0 0.5rem 0;
    }

    .explanation-question {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .yes-button {
      margin-bottom: 0.5rem;
    }

    .no-button {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}
