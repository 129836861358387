@import 'utilities.scss';

.button_copy {
  .copy-icon {
    fill: $electric-blue;
  }

  .message {
    position: absolute;
    height: 39px;
    line-height: 39px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(39, 49, 57, 0.15);
    background-color: #273139;
    padding: 0;
    font-size: 14px;
    color: white;
    width: 250px;
    top: -45px;
    margin-left: -125px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .info-icon {
      margin-right: 0.5rem;
    }
  }
}
