@import '../../utilities';

body {
  margin: 0;
}

.row.spacer {
  padding-bottom: $s-medium;
}

.main-layout {
  a {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px rgba(21, 114, 197, 0.2);
    }
  }

  h1.dsc-heading {
    padding-bottom: $s-normal;
  }

  h2.dsc-heading {
    margin: $s-normal 0 $s-medium 0;

    hr {
      margin-top: $s-normal;
    }
  }

  h3.dsc-heading {
    margin-bottom: $s-small;
  }

  .dsc-text {
    &.is-lead {
      margin-bottom: $s-large;
    }
    &.help-text {
      color: $grey600;
      margin-bottom: $s-xsmall;
    }
  }

  .dsc-tooltip svg.dsc-icon {
    fill: $electric-blue;
  }

  .dsc-fieldset.radio-label {
    legend {
      font-weight: $fw-normal;
    }
  }

  .dsc-formGroup,
  .dsc-fieldset {
    margin-bottom: $s-medium;
  }

  .collection-wrapper {
    .dsc-formGroup,
    .dsc-fieldset {
      margin-bottom: $s-normal;
    }
  }

  .layout-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  .accountCard {
    color: $grey700;
    background-color: white;
    padding: 1.5rem 2rem;
    margin-bottom: 8px;
    border-radius: 8px;
    &.selected {
      box-shadow: 0 0 0 1px $electric-blue;
      .label {
        color: $electric-blue;
      }
    }
    &.disabled {
      opacity: 0.5;
    }
    .row {
      padding: 0;
      align-items: center;
    }
    .label {
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    .cashAdvance {
      margin-top: $bao_height_margin;
    }
    .product-form-checkbox__label {
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
      }
    }
  }

  .layout__error {
    border-radius: 8px;
    background-color: $candy;
    color: $brand-red;
    margin: 0 -1rem 2rem -1rem;
    padding: 2rem 1rem;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul > li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .modal__header {
    padding: 1.5rem;
  }

  .modal__body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    min-height: 10rem;
  }

  .dsl-form__main {
    .dsl-form__scrollZone {
      // override default scrolling behavior to prevent content overflow when change police size https://jira.bnc.ca/browse/BAO-17596
      min-height: auto;
    }
  }
}
