.sidebar.with-menu {
  .dsl-sidebar__title {
    margin-bottom: 1.75rem;
    font-weight: 500;
  }

  &.without-name hr.dsc-divider {
    visibility: hidden;
  }

  .dsl-sidebar__navigationLink--locked {
    //cursor: not-allowed;
  }

  .dsc-tooltip__trigger {
    .dsc-btn {
      svg {
        fill: #838995;
      }
    }
  }

  .wrapper-request-number {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dsc-text {
      margin-left: 1rem;
    }
  }
}
