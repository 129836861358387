@import '../../utilities';

.request-number__form {
  .request-number-label {
    .dsc-text {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.5rem;
    }
  }
  .highlight {
    margin: 0 0 3rem;
  }

  .assistant-section-wrapper {
    display: flex;
    align-items: center;

    #request-assistant-checkbox {
      border-radius: 4px;
      width: 2em;
      height: 2em;
      -webkit-appearance: none;

      &:checked {
        border-color: transparent;
        background: #1572c5;
        position: relative;

        &::after {
          position: absolute;
          left: 50%;
          top: 55%;
          transform: translate(-50%, -50%);
          content: url('../../assets/icons/check/check.svg');
        }
      }
    }

    .request-assistant-label {
      margin-left: 10px;
    }
  }
}
