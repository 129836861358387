.employment__form {
  .phone-country-input {
    .extensionNumberInput {
      .dsc-label__container {
        .dsc-label__optional {
          text-wrap: nowrap;
        }
      }
    }
  }
}
