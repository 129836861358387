@import '../../utilities';

.thank_you__form {
  text-align: center;

  .button_link {
    text-decoration: none;
  }

  .checklist {
    margin-bottom: 1rem;

    .check-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      color: $electric-blue;
    }

    ul {
      text-align: left;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 1rem;
        position: relative;
        padding-left: 2rem;
        &:last-child {
          margin-bottom: 0;
        }       
      }
    }
  }

  .dsc-alert {
    display: flex;
    text-align: left;
    margin-bottom: 1rem;
    .dsc-alert__title {
      margin-bottom: 0;
    }
    .info-icon {
      color: $navy700;
    }
    p {
      margin: 0;
    }
  }

  .infolettre {
    border: none;
    background-color: $ice;
    padding: 1rem 2rem;

    .newsletter-icon {
      width: 100px;
      height: 100px;
      color: $electric-blue;
    }

    .text_beTheFirst {
      margin: 1rem 0;
    }
  }

  .button_close {
    margin: 1.5rem;
  }
}
