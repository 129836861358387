@import 'utilities.scss';

.error-icon {
  width: 5rem;
  height: 5rem;
  fill: $brand-red;
}

.error-message {
  padding: $bao_height_padding 0;
}
