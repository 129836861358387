@import 'utilities.scss';

.devnav {
  left: 0;
  right: 0;
  top: 0;
  height: 20px;
  background-color: lightgreen;
  position: fixed;
  z-index: 1000;
  line-height: 20px;
  text-align: center;
  color: darkgreen;
  font-weight: bold;
  font-size: 12px;
  border-bottom: 1px solid $grey100;
  button,
  select {
    border: 0;
    background-color: white;
    color: darkgreen;
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.1s ease-in-out;
    &:hover,
    &.active {
      background-color: darkgreen;
      color: lightgreen;
    }
  }
  select {
    height: unset;
    width: 70px;
    text-transform: capitalize;
  }
}
