@import '../../utilities';

#Transfer_form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .transfer-checkbox {
    .dsc-radio {
      margin-bottom: 0;
    }

    .dsc-message-employer {
      margin: 0;
    }

    .dsc-checkbox {
      .dsc-checkbox__text > span {
        font-weight: 400;
        font-size: 1.25rem;
      }
    }

    .dsc-formGroup {
      margin-bottom: 1rem;

      &.disabled-account {
        margin-bottom: 0rem;
      }

      .dsc-checkbox__text {
        font-family: $ff-gilroy;
        font-size: 1.25rem;
      }
    }
  }

  .dsc-fieldset {
    margin-top: 1rem;
  }

  .error {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
