@import 'utilities.scss';

.tax__form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .isMainResidencyCA.dsc-fieldset.radio-label .dsc-label {
    font-weight: $fw-medium;
  }

  .field_isOtherResidency.label {
    margin-bottom: $s-xsmall;
    font-weight: $fw-medium;
  }

  .noRevealReasonLegalInfo.dsc-text.help-text {
    margin-top: -$s-xsmall;
    margin-bottom: $s-medium;
  }

  .survivorship-warning-text {
    display: inline-flex;
    align-items: baseline;

    svg {
      overflow: visible;
      margin-right: 0.5rem;
    }

    .product-form-input__help.legal {
      margin: 0;
      color: $cherry;
      font-size: .875rem;
      margin-top: 0.5rem;
      font-weight: 500;
    }
  }
}
