@import '../../utilities';

.waiting__form {
  text-align: center;
  h1.title {
    margin: 0 auto;
  }
  .important {
    .dsc-heading {
      text-align: left;
    }

    ul {
      text-align: left;
      list-style: none;
      padding-left: 0;
      li {
        margin-bottom: $s-normal;
        position: relative;
        padding-left: $s-large;
        .dsc-icon {
          position: absolute;
          top: 0;
          left: 0;
          color: $electric-blue;
        }
      }
    }
  }
  .identity {
    padding: 1rem 0;
    p {
      margin: 0;
      &:last-child {
        margin-top: 0.5rem;
      }
    }
  }
  .steps {
    &.row {
      margin-bottom: 1rem;
    }

    .step_title {
      font-size: $fs-7;
      font-weight: $fw-medium;
      color: $black;
      padding-bottom: $s-normal;
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      .step-wrapper:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .infostep {
      border-radius: $s-xsmall;
      background-color: $ice;
      padding: 1rem;
      height: 100%;
      .dsc-icon {
        color: $electric-blue;
      }
      .section-title {
        span {
          justify-content: center;
        }
      }
      p {
        line-height: $lh-paragraph;
        font-size: $fs-3;
      }
    }
  }

  .documents-warning.dsc-alert {
    display: flex;
    margin-bottom: 1rem;

    .dsc-icon {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      align-self: start;
    }

    .dsc-text {
      margin-left: 0.5rem;
      text-align: justify;

      span {
        display: inline-block;
        font-size: $fs-3;

        &:first-child {
          font-weight: 600;
          font-size: $fs-4;
        }
      }
    }
  }

  .bnc-address {
    text-align: left;
    margin-bottom: $s-normal;
  }

  .help-section {
    border-radius: $s-xsmall;
    border: 1px solid $grey300;
    margin-bottom: $s-xxlarge;

    .row {
      padding: $s-normal 0;
      align-items: center;

      .advisor-icon {
        width: 80px;
        height: 80px;
      }

      .dsc-icon {
        color: $electric-blue;
      }

      .help-section-content {
        text-align: left;
        padding: 0 0.75rem;
      }
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      .row {
        align-items: flex-start;

        .advisor-icon {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
