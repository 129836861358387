@import 'utilities.scss';

@mixin handleBackGroundColor($color) {
  background-color: $color;
  border: 1px solid $grey100;
}

@mixin handleLine() {
  border-bottom: 1px solid $grey100;
}

.employment__form {
  .occupation-area {
    padding-left: 2.8rem;
    padding-bottom: 1rem;
  }

  .occupation-list {
    @include handleBackGroundColor($white);
    border-radius: 0.5rem;
    padding-left: 0;
    padding-right: 0;

    .active-occupations {
      @include handleLine();

      p.dsc-text {
        margin-left: 1rem;
      }

      .dsc-radio {
        border: none;
        padding: 1rem 1rem;
        margin-bottom: 0px;

        label {
          cursor: pointer;
        }
      }
    }

    .active-occupations-last {
      p.dsc-text {
        margin-left: 1rem;
      }

      .dsc-radio {
        border: none;
        padding: 1rem 1rem;
        margin-bottom: 0px;

        label {
          cursor: pointer;
        }
      }
    }
  }

  .occupation-section {
    .error-message {
      padding-top: 0px;
    }

    .dsc-tooltip {
      margin-left: 0.5rem;

      .dsc-tooltip__content {
        text-align: left;
        text-justify: inter-word;
      }
    }

    .dsc-formGroup {
      margin-bottom: 1rem;
    }

    .dsc-fieldset {
      margin-bottom: 1rem;
    }
  }
}
