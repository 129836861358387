@import '../../utilities';

#PaymentInformation_form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 0.75rem;
    color: $eternal-blue;
  }

  .title-info-container {
    display: flex;
    background-color: $blue075;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 3rem;

    svg {
      margin-right: 1rem;
      margin-top: 0.25rem;
    }

    p {
      font-size: 1rem;
      color: $navy600;
    }
  }

  .account-name {
    margin-bottom: 2rem;
  }

  .account-info-header {
    margin-bottom: 1rem;
  }

  fieldset {
    margin-top: 1rem;
  }

  .dsc-formGroup {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .account-divider {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .field-input {
    .dsc-formGroup {
      margin: 0 2rem 0.5rem 2rem;

      .dsc-label {
        margin-bottom: 0;
      }
    }
  }

  .dsc-fieldset {
    margin-bottom: 1rem;
  }

  .hidden {
    display: none;
  }
}

.splitPercentage-input {
  .dsc-input__container {
    .dsc-input {
      text-align: right;
      padding-right: 54px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .dsc-input__icon {
      border-left: solid 1px #b7b9be;
      background-color: #eeeeee;

      .dsc-icon {
        color: black;
        height: 24px;
        width: 16px;
      }
    }
  }
}

.alert-list-custom {
  padding-inline-start: 1.5rem;
}

.alert-list-description-text {
  font-size: 14px !important; // Ignored by default
  font-weight: normal !important; // Ignored by default
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important; // Ignored by default
  letter-spacing: normal;
  text-align: left;
}
