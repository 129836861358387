@import 'utilities.scss';

.finances__form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .fundingSource,
  .annualRevenue {
    .dsc-select {
      width: 48.8%;
      @media (max-width: $bao_breakpoint_small) {
        width: 100%;
      }
    }
  }

  .numberOfDependents {
    input {
      width: 15%;
      @media (max-width: $bao_breakpoint_small) {
        width: unset;
      }
    }
  }

  .dsc-formGroup {
    &.finances__assets-total,
    &.finances__liabilities-total {
      margin-bottom: 0.5rem;
    }
  }

  #AmountInput-assetsTotal,
  #AmountInput-liabilitiesTotal {
    cursor: pointer;
    background-color: #ffffff;
    color: #000000;
    -webkit-text-fill-color: #000000;
  }

  .finances__net-worth {
    .balance-format {
      line-height: 2.5rem;
      font-weight: lighter;
    }
  }

  .field_numberOfDependents {
    p {
      margin-bottom: 0;
    }
  }

  .is-paragraph.product-form-input__read-only-value {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-family: $ff-gilroy;
    font-weight: $fw-normal;
  }

  .calculator__label {
    display: flex;
    margin-left: 0.25rem;
    color: $electric-blue;
    font-size: 0.75rem;

    .calculator-icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }
}

.calculator {
  .row {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem 1rem 0.5rem;
    border-bottom: 0.0625rem solid #d5d5d5;

    &:first-child {
      padding-top: 0;
    }

    &:nth-last-of-type(2) {
      border-bottom: none;
    }

    &.total-assets {
      border-bottom: none;
      text-align: right;
      background-color: rgba(238, 246, 251, 0.85);

      .label-text {
        padding: 0.5rem 1rem 0.5rem 1rem;
      }

      p {
        font-size: 1.3125rem;
      }
    }

    .label-text {
      flex: 3;
      text-align: left;
      margin-left: -1rem;

      .dsc-label {
        margin-bottom: 0;
        font-size: 0.875rem;
      }

      p {
        font-size: 0.75rem;
      }
    }

    .dsc-formGroup {
      margin-bottom: 0;

      .dsc-label {
        margin-bottom: 0;
      }

      input {
        flex: 2;
        margin-left: 1rem;
        text-align: right;
        font-size: 1rem;
      }
    }
  }

  .bn-icon {
    color: $electric-blue;
  }

  .link {
    display: flex;
  }

  .rc-tooltip-content h2.dsc-heading {
    margin-top: 0;
  }
}
