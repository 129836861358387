@import '../../utilities';

.highlight {
  background-color: white;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;
  color: $navy600;
  p {
    padding: 0;
    margin: 0;
  }
  svg {
    width: 60px;
    height: 60px;
    margin-right: 1rem;
    fill: $navy600;
  }
}
.highlight__icon {
  width: 60px;
  height: 60px;
  margin-right: 1rem;
}
