@import 'utilities.scss';

.specific-banking-info__form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .collection {
    margin-bottom: 0;
  }

  .banking-accounts-wrapper .dsc-heading {
    margin: 0 0 $s-normal 0;
  }

  .send-cheque-wrapper {
    margin-bottom: $s-normal;

    .send-cheque-details {
      margin-top: $s-normal;
      color: $grey600;
    }

    .send-cheque-address {
      margin-top: $s-normal;
      margin-bottom: $s-normal;
      color: $grey600;
    }
  }

  .dsc-alert.is-error {
    margin-bottom: $s-normal;
  }

  .collection__card.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.bankingAccount {
  .dsc-radio {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: $fw-bold !important;
}

.bankingAccountsSafeId {
  .dsc-radio {
    margin-bottom: 0;
  }

  .add-manual-bank {
    margin-bottom: $s-medium;
  }

  .collection {
    .collection__item {
      .bankingAccountSafeId {
        .dsc-card {
          .dsc-heading {
            margin: 0;
          }
        }

        .bankingAccountSafeIdOpen {
          .dsc-card__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .bankingAccountSafeIdSuccess {
          .dsc-card__content {
            .dsc-heading {
              margin-bottom: $s-xsmall;
            }

            .dsc-card {
              margin-bottom: $s-xsmall;
              padding: 0;

              .dsc-card__content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > div {
                  display: flex;
                  align-items: left;

                  & > * {
                    &:not(:last-child) {
                      margin-right: $s-xsmall;
                    }

                    &:last-child {
                      margin-left: $s-normal;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &:not(:last-child) {
        .bankingAccountSafeId {
          margin-bottom: $s-xsmall;
        }
      }
    }
  }
}

.check-details {
  .dsc-popover {
    .dsc-popover__bubble {
      @media (max-width: $bao_content_max_width) {
        transform: translateX(-25%);
      }
    }

    .dsc-heading {
      font-weight: bold;
      margin: 0;
    }
  }

  .check-details__content {
    .specimen-check {
      margin: $s-small 0;
      width: 100%;
    }
  }
}

.confirmed-bank-accounts {
  margin-bottom: $s-large;

  .dsc-checkbox {
    width: 100%;

    .dsc-checkbox__text {
      width: 100%;

      .dsc-label {
        font-weight: normal;

        .dsc-label__container {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .dsc-icon {
            margin-right: $s-xsmall;
          }

          .dsc-label__optional {
            font-size: $fs-400;
          }
        }
      }
    }
  }
}

.modal-component.safe-id-delete-bank-account-modal {
  .dsc-modal__body {
    text-align: left;

    .dsc-radio {
      position: relative;
    }
  }

  .dsc-modal__footer {
    justify-content: flex-end !important;
  }
}

.FERRUSD-banking-explanation-list {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 1rem;
}

.FERRUSD-banking-explanation {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

.FERRUSD-banking-explanation-list-item {
  flex-grow: 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
}

.FERRUSD-banking-explanation-list-item-bold {
  flex-grow: 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}
