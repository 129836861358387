@import 'utilities.scss';

.devnav {
  button.inspect-sso {
    span:before {
      content: '\A';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;
      margin: 0 0.2rem 0 0;
      background: $black;
      border-radius: 50%;
    }
  }

  button.inspect-sso.online .status:before {
    background: $mint;
  }

  button.inspect-sso.offline .status:before {
    background: $cherry;
  }
}
