@import 'utilities.scss';

.manual-address-form__cancel-link {
  margin-bottom: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  color: #1572c5;
  font-family: 'gilroy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  display: flex;
}

.manual-address-form__delete-icon {
  margin-right: 0.4rem;
}
