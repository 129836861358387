@import 'utilities.scss';

.main-layout .layout-content {
  display: block !important;
}

.client-redirect-wrapper {
  max-width: 45.25rem;
  margin: -1rem auto 0 auto;

  h1.dsc-heading {
    padding-bottom: 0 !important;
  }

  /*Fix un bug*/
  .dsc-card.is-borderShadow .dsc-radio__label,
  .dsc-card.is-borderShadow .dsc-checkbox__label {
    box-shadow: none !important;
  }

  .dsc-card__content {
    .dsc-radio {
      margin-bottom: 0;

      .dsc-radio__label {
        span.dsc-heading {
          margin-top: 0.25rem;
        }
      }
    }
  }

  .spc-choix {
    &__subtitle {
      border-left: 1px solid $black;
      padding: $s-xsmall $s-normal;
      margin-left: $s-normal;

      h2.dsc-heading {
        margin: 0;
      }
    }

    &__hiddenContent {
      padding-left: $s-large;
      padding-top: $s-normal;

      .spc-avantCommencerList {
        padding: 0;
        margin: 0;

        &__icon {
          flex-shrink: 0;
          color: $eternal-blue;
          width: calc(#{$s-large} + #{$s-xsmall});
          margin-right: $s-normal;
          height: auto;
        }
      }
    }
  }

  .no-account-link-wrapper,
  .pending-request-link-wrapper {
    margin-top: 1.5rem;
    font-size: 0.875rem;
  }
}

.documents-popup {
  color: #606773;
  text-align: left;
  font-size: 0.875rem;

  .documents-list-explanation {
    margin-bottom: 1rem;

    p {
      font-weight: bold;
    }

    li.dsc-textList__item::before {
      content: '\2022' !important;
    }
  }
}

.steps-popup {
  .authentification-icon {
    margin-top: -2.4rem;
  }

  .authentification-explanation-wrapper {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem #d5d5d5 solid;

    .authentification-explanation {
      display: flex;
      font-size: 1rem;

      .authentification-stepNumber {
        border-radius: 50%;
        background: #badcf7;
        color: #000000;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        font-size: $fs-300;
        font-weight: $fw-semi-bold;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
      }

      .step-explanation {
        text-align: left;
      }
    }

    .dsc-collapsible {
      margin-top: 1rem;
    }
  }

  .other-validation-wrapper {
    text-align: left;
  }
}
