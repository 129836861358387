@import '../../utilities';

.request_assistant_form {
  .dsc-formGroup {
    margin-bottom: 0;
  }
  .request_assistant_advisor {
    border-bottom: solid 1px $grey100;
    margin-bottom: 1.5rem;
  }
}
