@import '../../../utilities';

.product-form-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .dsc-radio {
    .dsc-radio__label {
      padding: 1rem 1.5rem 1.5rem 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-height: 3rem;

      &::before,
      &::after {
        content: none;
      }

      .dsc-radio__text {
        text-align: center;
      }
    }
  }
}
