@import 'utilities.scss';

.main-layout .layout-content {
  display: block !important;
}

.create-user-profile-wrapper {
  max-width: 45.25rem;
  width: 100%;
  margin: -1rem auto 0 auto;

  h1.dsc-heading {
    padding-bottom: 0 !important;
  }

  .field-wrapper {
    margin-bottom: 30px;

    .has-error {
      background-color: #fbe7ee;
      border-color: #e41c23;
    }

    .error-field-area {
      color: #e41c23;
      font-size: 0.75rem;
      margin: 0.25rem 0 0 0;
    }
  }

  .consent-wrapper .dsc-formGroup {
    margin-bottom: 0;
  }

  //Validations
  .pwd-with-validation__items {
    margin-bottom: 1rem !important;
  }

  .pwd-with-validation__item {
    text-align: left;
    margin-top: 0.5rem;
    align-items: center;
    display: flex;
    color: #606773;
    font-weight: normal;
    font-size: 0.875rem;
  }

  .pwd-with-validation__item .bn-icon {
    /* Overriding translate property to ensure check icon position */
    transform: translateY(0) !important;

    svg {
      transform: scale(0.75);
    }
  }

  .pwd-with-validation__icon-wrapper-item {
    background-color: #d2d2d2;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.25s ease-in-out;
    display: inline-flex;
  }

  .pwd-with-validation__icon-item {
    margin: 0;
    color: #d2d2d2;
    visibility: hidden;
    transition: color 0.25s ease-in-out;
    transform: none;
  }

  .pwd-with-validation__label-item {
    color: #565656;
    transition: color 0.25s ease-in-out;
    display: inline-flex;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__icon-wrapper-item {
    background-color: #048037;
    animation: scalebounce 0.75s;
    transform: scale 0.25s ease-in-out;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__icon-item {
    visibility: visible;
    color: #fff;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__label-item {
    color: #048037;
  }

  @keyframes scalebounce {
    0% {
      transform: scale(1.02);
    }
    25% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.9);
    }
  }

  .dsc-alert.is-error {
    margin-top: 1rem;
  }

  .spc-creationProfil {
    &__card {
      overflow: hidden;
      padding: 0;
      border: 0;

      &.is-disabled {
        color: $grey400;

        .spc-creationProfil__stepNumber {
          background-color: $grey400;
        }

        .spc-creationProfil__cardContent,
        .spc-creationProfil__cardSpacer,
        .spc-creationProfil__cardFooter {
          display: none;
        }
      }

      .spc-creationProfil__cardContent.card2 {
        margin-top: 1.5rem;
      }
    }

    &__card.confirmation-card {
      margin-top: 1.5rem;
    }

    &__securityHeader {
      background: $peppermint;
      overflow: hidden;
      display: flex;
      padding: $s-normal;

      svg {
        flex-shrink: 0;
      }

      .profil-explanation {
        font-weight: $fw-semi-bold;
      }

      li.dsc-textList__item::before {
        content: '\2022' !important;
      }
    }

    &__cardBody {
      display: grid;
      padding: $s-medium;
      grid-template-columns: $s-large 1fr;
      gap: 0px $s-normal;

      grid-template-areas:
        'a b'
        '. c';

      &--fullContentMobile {
        grid-template-areas:
          'a b'
          'c c';

        .consent-explanation {
          margin-top: 1.5rem;
        }
      }

      .dsc-checkbox__label {
        box-shadow: none !important;
      }

      h2.dsc-heading {
        margin: 0;
      }
    }

    &__popupCardBody {
      padding: 2rem 2rem 3rem 2rem;
      text-align: center;

      .text-wrapper {
        max-width: 29rem;
        margin: 0 auto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
        text-align: left;

        .popup-title {
          font-family: $ff-gilroy;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.21;
          color: $cheddar;
          text-align: center;
        }

        .email-explanation {
          margin: 1.5rem 0 0.75rem 0;
          font-size: 1.1rem;
          font-weight: 500;
        }

        .application-question {
          margin: 1rem 0 0.5rem 0;
          font-size: 1rem;
          font-weight: 500;
        }

        .radio-title {
          font-size: 1rem;
          font-weight: 500;
        }

        .extra-explanation {
          font-size: 0.875rem;
          text-align: center;
        }

        .info-block {
          margin: 1.5rem 0 0.875rem 0;
          padding: 0.75rem;

          .dsc-alert__title {
            font-size: 0.75rem;
            font-weight: 400;
          }
        }

        li.dsc-textList__item::before {
          content: '\2022' !important;
        }
      }

      .confirmation-text-wrapper {
        max-width: 29rem;
        margin: 0 auto;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;

        .confirmation-title {
          font-size: 1.3125rem;
          font-family: $ff-gilroy;
        }

        .confirmation-text {
          font-size: 0.875rem;
        }
      }
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      &__popupCardBody {
        padding: 2rem 1rem 3rem 1rem;
      }
    }

    &__stepNumber {
      background: $navy900;
      color: $white;
      width: $s-large;
      height: $s-large;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: $fs-300;
      font-family: $ff-system;
      grid-area: a;
    }

    &__stepTitle {
      display: flex;
      align-self: center;
      grid-area: b;

      .dsc-tooltip {
        margin-left: 1rem;
      }

      .dsc-link.has-icon {
        margin-left: 1rem;
      }
    }

    &__cardContent {
      grid-area: c;
    }

    &__cardContent.user-email,
    &__cardContent.user-email p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__cardFooter {
      display: flex;
      justify-content: flex-end;
      padding: $s-normal $s-medium;
      background: $grey050;
      overflow: hidden;
    }

    @media (min-width: $bp-small) {
      &__cardBody {
        display: grid;
        padding: $s-medium;
        grid-template-columns: $s-large 1fr;
        gap: 0px $s-normal;

        &--fullContentMobile {
          grid-template-areas:
            'a b'
            '. c';
        }
      }
    }
  }

  .about-you__form {
    .field-wrapper {
      input {
        margin-top: 0.25rem;
      }

      .has-error {
        background-color: #fbe7ee;
        border-color: #e41c23;
      }

      .error-field-area {
        color: #e41c23;
        font-size: 0.75rem;
        margin: 0.25rem 0 0 0;
      }
    }

    .dsc-radio__label {
      box-shadow: none;
    }

    .dsc-tooltip__content {
      text-align: center;
    }
  }
}
