@import '../../utilities';

.finalisation__form {
  text-align: left;

  p {
    padding: 0;
    margin: 0 0 0.5rem 0;
  }

  .image-row {
    text-align: center;

    img {
      max-height: 150px;
    }
  }

  ul {
    margin-top: 0.75rem;
  }

  .title {
    width: 100%;
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  .finalisation-container-row {
    .finalisation-card-row {
      background-color: white;
      padding: 1.5rem 2rem;
      margin-bottom: 0;
      border-left: solid 1px $grey100;
      border-right: solid 1px $grey100;
      border-top: solid 1px $grey100;
      border-radius: 0;

      &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }

      &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
        border-bottom: solid 1px $grey100;
      }

      .finalisation-card-label {
        font-weight: 600;
        font-size: 1.25rem;
        padding-bottom: 0.625rem;

        .finalisation-step-number {
          display: inline-flex;
          text-align: center;
          line-height: 1.25rem;
          margin-left: -0.5rem;
          width: 1.5rem;
          height: 1.5rem;
          border: solid 1px $grey400;
          border-radius: 50%;
          color: $grey400;
          background-color: white;
          justify-content: center;
          align-items: center;
        }

        .finalisation-step-title {
          display: inline-block;
          padding-left: 1rem;
        }
      }

      .finalisation-card-content {
        margin-left: 1.625rem;
        font-size: $fs-3;
        padding-bottom: 0;

        .documents-links-row {
          ul {
            padding-left: 0;
          }

          li {
            list-style-type: none;
            display: flex;
            align-items: center;
            vertical-align: middle;
            // height: 1.75rem;
            // line-height: 1.75rem;

            .link,
            a {
              font-size: $fs-3;
              text-decoration: none;
              color: $electric-blue;
              font-family: $ff-gilroy;
              height: unset;
              line-height: 1rem;
              padding: 0.25rem 0;
              letter-spacing: 0.4px;
              user-select: none;
              font-weight: 600;

              &:hover,
              &:focus {
                color: unset;
                cursor: pointer;
              }
            }

            .link {
              border: none;
              text-align: start;
              border-radius: unset;

              &:hover,
              &:focus {
                background-color: transparent;

                &:after {
                  opacity: 0;
                }
              }
            }

            .sidebar-menu__step__icon--completed {
              position: static;
              margin-right: 0.5rem;
              height: $fs-3;
              width: $fs-3;
              flex-shrink: 0;
            }
          }
        }

        .notes-links-row {
          ul {
            padding-left: 0;
            font-size: $fs-2;
            margin-left: 1.25rem;
          }

          li {
            margin-bottom: 0.5rem;
          }
        }

        .multi-section {
          margin-top: 0.5rem;

          .documents-links-title,
          .notes-links-title {
            font-weight: 600;
          }
        }
      }
    }

    .address-row {
      line-height: 1.5;
      font-size: 1rem !important;
    }
  }

  .error {
    padding: $bao_height_padding 0;
  }
}
