@import 'utilities.scss';

.dsl-form__main-wrapper {
  width: 100%;
}

.pdf-viewer {
  padding-right: 1.5rem;

  .pdf-title {
    display: flex;
    justify-content: space-between;

    .pdf-buttons {
      display: flex;
      a {
        height: fit-content;
        button {
          height: 100%;
        }
      }
      button {
        height: fit-content;
        &:last-child {
          margin-left: 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: $bao_content_mobile_width) {
    .pdf-title {
      margin-bottom: 1rem !important;
    }
  }

  .pdf-description {
    padding-bottom: 1rem;
  }

  canvas {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  .pdf-pagination {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
