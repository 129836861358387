@import 'utilities.scss';

.accountsStep3__form {
  .account-section {
    .info-icon {
      color: $electric-blue;
    }

    .utilisation-label {
      margin-bottom: 1rem;
    }

    .label {
      display: inline-grid;
    }

    .dsc-formGroup {
      margin-bottom: 1rem;
    }

    .accountCard {
      padding: 1rem;
      margin-top: 1rem;

      .dsc-formGroup {
        margin-bottom: 0;
      }

      & .row:not(:first-of-type) {
        .dsc-formGroup {
          margin-top: 1rem;
        }
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
      }

      .utilisation__header {
        margin-bottom: 0.75rem;
        display: flex;
        align-items: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          flex-shrink: 0;
        }

        @media screen and (min-width: $bao_breakpoint_small) {
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }

        h3.account {
          margin-bottom: 0rem;
        }
      }
    }
  }

  .account,
  .currency {
    margin-right: 10px;
  }

  .promotion {
    margin-top: 2rem;

    .promotionCdAvailable {
      display: flex;
      align-items: center;
      height: 2.75rem;
    }

    .tradingOptions {
      font-size: $fs-3;
      color: $grey700;
    }
  }

  .is-paragraph.tradingOptions {
    margin-top: $s-normal;
    margin-bottom: $s-normal;
  }
}
