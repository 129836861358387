@import '../../utilities';

@mixin disablePhoneAndEmail() {
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: default;
  color: $black;
  text-overflow: ellipsis;
}

.esign-form {
  .identity-validation-box {
    &.validation-completed {
      .idv-header {
        margin-bottom: 0;
      }
    }

    .idv-header {
      margin-bottom: 0;
    }

    .idv-title {
      width: 100%;

      @media screen and (max-width: $bao_content_mobile_width) {
        .title-wrapper {
          position: relative;

          .status-wrapper {
            position: absolute;
            top: 4rem;
            margin-top: 1rem;
          }
        }
      }

      .check-icon {
        vertical-align: middle;
        height: 16px;
        width: 16px;
      }

      .status-description {
        margin-left: $s-xsmall;
        color: $mint;
        text-align: right;
        font-size: 0.875rem;
      }

      .status-wrapper {
        display: flex;
        align-items: flex-end;

        .status {
          padding-right: $s-small;
        }

        .link-modify-wrapper {
          padding-left: $s-small;
          border-left: 0.0625rem solid #eeeeee;
          .link-modify {
            display: block;
            text-align: right;
            color: $electric-blue;
            font-size: $fs-300;
            cursor: pointer;
            text-decoration: underline;
            margin-bottom: 0.125rem;
          }
        }
      }
    }

    .idv-content {
      @media (min-width: $bao_content_max_width) {
        margin-left: 0;
        margin-bottom: 0;
      }
      border-bottom: none;

      .paper-sign {
        margin-bottom: 0;

        h4 {
          font-size: $fs-400;
        }

        .dsc-heading {
          margin-bottom: $s-xsmall;
        }

        .dsc-formGroup {
          margin-bottom: 0;

          &.paper-sign-text {
            .dsc-checkbox__text {
              font-size: $fs-300;
            }
          }
        }
      }
      .esign-info-container {
        p {
          color: $black;
        }

        .esign-confirm-info-title {
          margin-bottom: $s-normal;
        }

        .esign-info-email.disabled,
        .esign-info-phone.disabled {
          .dsc-formGroup {
            .dsc-input,
            .dsc-select {
              @include disablePhoneAndEmail();
            }
          }
        }

        .esign-info-email,
        .esign-info-phone {
          display: grid;
          grid-template-columns: 90px auto 10%;
          grid-gap: 0.625rem;
          margin-bottom: 0.625rem;

          @media (max-width: 575px) {
            grid-template-columns: 90px auto;
          }

          @media (min-width: 1200px) {
            grid-template-columns: 160px auto 10%;
          }

          .dsc-select {
            background: none;
            padding: 0.375rem 0 0.375rem 0.5rem;
            opacity: 1;
            @include disablePhoneAndEmail();
          }

          .link-modify {
            margin-top: 0.625rem;
            font-size: $fs-300;
          }

          & > span {
            margin-top: 0.625rem;
          }

          .dsc-formGroup {
            margin-bottom: 0;
          }

          .action-buttons-container {
            display: flex;

            .dsc-iconBtn {
              width: 1.875rem;
              height: 1.875rem;

              svg {
                width: 1.5rem;
                height: 1.5rem;
              }
            }

            .action-confirm-changes {
              margin-right: $s-xxsmall;
              background: $electric-blue;
              color: $white;
            }

            .action-confirm-changes,
            .action-cancel-changes {
              margin-top: 0.375rem;
            }

            .action-cancel-changes:hover {
              background: transparent;
              color: $electric-blue;
            }
          }
        }
        .esign-info-phone .product-form-phone-input {
          align-items: stretch;
        }
      }
    }
  }

  .esign-section {
    color: $grey600;
    background-color: $white;
    margin-bottom: $s-normal;
    border-radius: $s-xsmall;

    .esign-section-confirmation {
      display: flex;
      flex-direction: column;
      padding: $s-medium;

      h4 {
        margin-bottom: $s-xsmall;
      }

      .dsc-formGroup {
        margin-bottom: 0;
      }

      .section-title {
        margin-top: 0;
      }

      & > .row {
        align-items: center;
        margin-top: 0;
        font-size: $fs-3;

        span {
          color: $grey600;
          font-size: $fs-3;
        }

        &.safely {
          margin-top: $s-normal;
          margin-bottom: 0;
          .help-text {
            color: $grey600;
          }
        }

        .dsc-text {
          color: $black;
        }

        &.phone-section {
          .phone-type {
            color: $black;
          }
        }

        .link-modify-input {
          display: block;
          text-align: right;
          color: $electric-blue;
          font-size: $fs-300;
          cursor: pointer;
          text-decoration: underline;
          margin-bottom: 0.125rem;
        }
      }
    }

    .esign-section-content {
      display: flex;
      flex-direction: column;
      padding: 1.75rem;
      background-color: $blue075;
      border-radius: $br-medium;

      .dsc-formGroup {
        margin-bottom: 0;
      }

      .section-title {
        margin-top: 0;
      }

      .row {
        align-items: center;

        .docusign-icon {
          color: $black;
        }
        &.safely {
          margin-top: $s-normal;
          .help-text {
            color: $grey600;
          }
        }
        .dsc-text {
          color: $black;
        }

        .docusign-description {
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 0.75rem;
        }
      }
    }
    .seperator {
      width: 100%;
      height: 0;
      border-bottom: 1px solid $grey300;
    }
  }

  .us-sources {
    .dsc-radio__text {
      .dsc-radio__info {
        margin-top: $s-xsmall;
      }
    }
    .id-details {
      list-style-type: disc;
      padding-left: $s-normal;
    }
    .dsc-errorMessage {
      &::before {
        display: none;
      }
      font-weight: $fw-normal;
    }
  }
  .dsc-radio {
    position: relative;
  }
}

.modal-component.esign-modal {
  .dsc-modal__body {
    text-align: left;

    .us-sources {
      .dsc-radio__text {
        .dsc-radio__info {
          margin-top: $s-xsmall;
        }
      }
      .id-details {
        list-style-type: disc;
        padding-left: $s-normal;
      }
      .dsc-errorMessage {
        &::before {
          display: none;
        }
        font-weight: $fw-normal;
      }
      .dsc-combo {
        .dsc-combo__item {
          .dsc-radio {
            margin-bottom: 0.1875rem;
          }
        }
      }
    }
    .dsc-radio {
      position: relative;
    }
  }

  .dsc-modal__footer {
    justify-content: flex-end !important;
  }
}
