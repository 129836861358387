@import 'utilities.scss';

.regulatory__form {
  .dsc-fieldset.no-bold {
    .dsc-label {
      font-weight: $fw-normal;
    }
  }

  .dsc-combo .dsc-combo__item {
    .dsc-radio {
      margin-bottom: 0;
    }
  }

  .regulatory-text-legal {
    display: inline-flex;
    align-items: baseline;

    svg {
      overflow: visible;
      margin-right: 0.5rem;
    }

    .product-form-input__help.legal {
      margin: 0;
      color: $cherry;
      font-size: .875rem;
      margin-top: 0.5rem;
      font-weight: 500;

      a.dsc-link {
        color: $cherry;
      }
    }
  }
}
