@import "utilities.scss";
.identityVerification__form {

  .bank-reference{
    margin: 1.5rem 0 1rem;
    .dsc-heading {
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  .bankingAccount {
    background-color: #e2eff8;
    padding: 1.5rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
  }

  .dsc-fieldset {
    .long-description {
      color: $grey600;
      font-size: $fs-3;
      padding: 0;

      li {
        list-style: none;
        background: url('../../assets/icons/check/ico-check-black.svg') no-repeat 0px 0.0625rem;
        padding: 0 0 0.125rem 1.25rem;
        font-size: .75rem;
      }

      &.first {
        margin-bottom: .5rem;
        font-weight: normal;
      }

      p{
        margin: 0;
        padding: 0;
      }
    }

    .dsc-text.is-paragraph {
      font-weight: 500;
      margin-bottom: .5rem;
    }

    .dsc-collapsibleItem {
      .dsc-combo__collapsibleItem {
        .dsc-radio {
          margin-bottom: .5rem;
        }
        
        .dsc-radio__text {
          &:first-child{
            font-weight: 500;
          }

          .dsc-radio__info .long-description.first {
            margin-bottom: 0;
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
