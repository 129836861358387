.collection {
  margin-bottom: 2rem;

  .collection__card {
    background-color: #e2eff8;
    padding: 1rem 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;

    &.add {
      background-color: transparent;
      margin-bottom: 0rem;
      padding-bottom: 0rem;
    }

    & .row:last-child {
      padding-bottom: 0;
    }
  }

  .collection__button {
    &.delete {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.25rem;
    }
  }

  .collection__item.row {
    .dsc-formGroup {
      margin-bottom: 0.5rem;
    }

    &.add {
      margin-top: 0.5rem;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
