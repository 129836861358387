@import 'utilities.scss';

@mixin handleBackGroundColor($color, $cursor) {
  background-color: $color;
  border-bottom: 1px solid $grey100;

  legend {
    background-color: $color;
    margin-bottom: 0;

    .dsc-label__container {
      margin-bottom: 0;

      .dsc-checkbox {
        padding: 1rem 1rem 0rem 1rem;

        .dsc-checkbox__text {
          font-size: 1.125rem;
          font-weight: 500;
        }

        input,
        label {
          cursor: $cursor;
        }
      }
    }
  }

  .dsc-fieldset__introduction {
    margin: 0rem 2.5rem 1rem 3.25rem;
  }
  .dsc-fieldset__description {
    margin: -1rem 2.5rem 1.25rem 3.25rem;
  }

  .account-currencies {
    padding: 0.5rem;
    margin: 1rem 2.5rem 1rem 3.25rem;
    background-color: $ice;
    border-radius: 0.5rem;

    p.dsc-text {
      margin-left: 1rem;
    }

    .dsc-checkbox {
      border: none;
      padding: 0.5rem 1rem;

      label {
        cursor: pointer;
      }

      &.is-disabled {
        label {
          cursor: not-allowed;
        }
      }
    }
  }
}

.accountsStep1__form {
  .dsc-progressSpinner {
    margin: 0 auto $s-medium;
  }

  .accounts-wrapper {
    box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .nonRegisteredAccounts,
  .registeredAccounts {
    h2.dsc-heading {
      margin: 1.5rem 0 1.5rem 0;
    }

    .accounts-section-title {
      display: flex;
      align-self: center;
      align-items: center;

      .dsc-tooltip {
        margin-left: 0.5rem;

        .dsc-tooltip__content {
          text-align: left;
          text-justify: inter-word;
        }
      }
    }

    fieldset.dsc-fieldset {
      @include handleBackGroundColor($white, pointer);

      &:first-of-type {
        legend {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }

      &:last-of-type {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &.is-disabled {
        @include handleBackGroundColor($grey075, not-allowed);
      }
    }
  }

  .nonRegisteredAccounts {
    margin-top: 1.5rem;
  }

  .account-type-selector {
    svg {
      width: 40px;
      height: 40px;
      flex-grow: 0;
    }
    
    .dsc-radio {
      margin-bottom: 0;

      .client-type-description {
        margin-left: 0.5rem;

        .client-type-title {
          font-size: 1.125rem;
          font-weight: 500;
        }
      }
    }

    fieldset.dsc-fieldset {
      margin-bottom: 0;

      .dsc-combo__item:first-child {
        border-bottom: 1px solid $grey100;
      }

      ul.disabled-combo {
        li.dsc-combo--form:not(.is-collapsable) * {
          cursor: not-allowed;
        }
      }
    }

    .dsc-alert {
      background-color: inherit;
      color: $navy600;
      margin-left: 2rem;
    }
  }

  a.more-information-link {
    margin-top: $s-medium;
  }

  .client-type {
    &.row {
      margin: 0;
    }

    label {
      margin-right: $s-normal;
    }

    fieldset {
      margin-bottom: 0;
    }
  }

  .fhsa-explanation {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: $navy050;

    .fhsa-information {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .fhsa-explanation-list {
      margin-left: 1.5rem;

      .fhsa-explanation-item {
        font-size: 0.85rem;
        font-weight: 600;
      }

      li.dsc-textList__item::before {
        content: '\2022' !important;
      }
    }

    a.more-details-link {
      p.dsc-text {
        margin-left: 1.5rem;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    .dsc-formGroup {
      margin-left: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;

      .dsc-checkbox__label {
        border: none;
        cursor: pointer;
      }

      .dsc-checkbox__input.invalid-field {
        display: none;

        & + *::before {
          content: '';
          display: inline-block;
          vertical-align: center;
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 10%;
          border: 0.25rem solid #c00011;
        }
      }

      .dsc-checkbox__text {
        font-weight: 500;
      }
    }
  }
}
