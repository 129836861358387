@import 'utilities.scss';

.summary__form {
  .product-form-input__label {
    font-weight: 600;
  }

  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .dsc-alert.is-small {
    padding: 0.75rem 0rem 0.75rem 0rem;

    .dsc-alert__title {
      font-size: 0.75rem;
      color: #386c93;
      font-weight: 500;
    }
  }
}
