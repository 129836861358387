@import 'utilities.scss';

.assisted-redirector__container {
  .row {
    margin-bottom: 1.5rem;
  }

  .dsc-text.is-lead.sub-title {
    margin-bottom: 1rem;
  }

  .information-row {
    .check-icon-container {
      flex-basis: auto;
      .checkIcon {
        width: 1rem;
        height: 1rem;
        color: $electric-blue;
      }
    }
    .information {
      margin: 0;
      font-size: $fs-3;
      color: $black;
    }
  }

  .options-list {
    list-style-type: none;
    padding-inline-start: 0;

    .option-row {
      align-items: flex-start;

      .icons-container {
        flex-basis: auto;
        .icons {
          height: 2rem;
          width: 2rem;
          path {
            fill: $electric-blue;
          }
        }
      }
      .requirement {
        margin: 0;
        font-size: $fs-6;
        font-weight: $fw-semi-bold;
      }
      .requirement-desc {
        margin: 0;
        font-size: $fs-2;
        color: $grey700;
      }
    }
  }

  .button-row {
    .buttons-list {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-basis: auto;

      .action-button {
        &:hover,
        &:focus {
          background: #1572c5;
          color: #fff;
        }
      }
    }
  }

  .security-explanation-row {
    margin-top: 2rem;

    .security-explanation-col {
      height: 3.5rem;
      border-radius: 0.5rem;
      background-color: #c9e4eb;
      padding-bottom: 0px;
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      flex-basis: auto;

      .security-icon {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
