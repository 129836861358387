@import '../../utilities';

@mixin unbordered-fw-card($margin-top) {
  border: none;
  width: 100%;
  margin-top: $margin-top;
}

@mixin custom-card($margin-top, $margin-left) {
  background: #f5f5f5;
  margin-top: $margin-top;
  border: 1px solid #f5f5f5;
  margin-left: $margin-left;
  padding: 1rem;
}

@mixin fontWeightAndSize($weight, $size) {
  font-weight: $weight;
  font-size: $size;
}

.identity-validation-form,
.esign-form {
  .identity-validation-box {
    padding: 1.375rem 2rem 1.5rem 1.5rem;
    @include unbordered-fw-card(1rem);

    .dsc-alert.is-warning {
      margin-bottom: 1rem;
      .dsc-alert__textContainer {
        margin-top: 0;
        .dsc-alert__title,
        .dsc-alert__description {
          color: #000000;
        }

        .dsc-alert__title {
          font-size: 0.8675rem;
        }

        .dsc-alert__description {
          margin-top: 0;
        }
      }
    }

    .idv-header {
      margin-bottom: 1rem;
      display: flex;
      .dsc-icon {
        width: 2rem;
        height: 2rem;
        @media (max-width: $bao_content_max_width) {
          width: 4rem;
        }
      }

      .idv-title {
        margin-left: 1.25rem;
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        margin-top: 0rem;

        .title-wrapper {
          display: flex;
          justify-content: space-between;
          @media (max-width: $bao_content_max_width) {
            flex-direction: column;
          }
        }

        .idv-title-label {
          margin: 0;
          font-family: $ff-gilroy;
        }
        .idv-title-description {
          margin: 0.25rem 0 0;
          font-size: 0.875rem;
          color: #565656;
        }
      }
    }

    .idv-content {
      @media (min-width: $bao_content_max_width) {
        margin-left: 3rem;
      }
      border-bottom: 1px solid #d5d5d5;
      margin-bottom: 1.5rem;

      & > .dsc-formGroup:last-child {
        & > .dsc-fieldset {
          .dsc-combo {
            margin: 0rem 0rem 0rem 2rem;
          }
        }
      }

      & > .us-sources {
        padding-left: 2.25rem;
        .dsc-card {
          @include custom-card(0.25rem, 0);
          margin-bottom: 1rem;
          ul {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: -2.25rem;

            li {
              list-style: none;
              background: url('../../assets/icons/check/ico-check-black.svg') no-repeat 0px 0.0625rem;
              padding: 0 0 0.125rem 1.25rem;
              font-size: 0.75rem;
              color: $grey600;
            }
          }
        }

        .dsc-formGroup {
          margin-bottom: 0.5rem;
        }

        .dsc-fieldset {
          margin-bottom: 0;
        }
      }

      & > .dsc-formGroup {
        margin-top: -0.75rem;
        margin-bottom: 0rem;
        padding-top: 1rem;

        & > .dsc-fieldset {
          margin-bottom: 0rem;

          .dsc-radio {
            &[data-test='radio-ALTERNATE'] {
              border-top: 1px solid #d5d5d5;
              padding-top: 1rem;
            }
            margin-bottom: 0.6875rem;
            .dsc-radio__label {
              &::before {
                margin-right: 0.625rem;
              }
              .dsc-radio__text {
                &:first-child {
                  @include fontWeightAndSize(500, 1rem);
                }
              }
            }
          }
          .dsc-combo {
            & > .dsc-combo__item {
              .dsc-radio {
                margin-bottom: 0;

                .dsc-radio__label {
                  padding: 1rem;

                  .dsc-radio__text {
                    &:first-child {
                      @include fontWeightAndSize(normal, 0.875rem);
                    }

                    .dsc-radio__info .long-description.first {
                      @include fontWeightAndSize(normal, 0.75rem);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .us-sources {
        .dsc-formGroup {
          margin-bottom: 1rem;
          &.has-error {
            .dsc-fieldset {
              margin-bottom: 0;
            }

            .dsc-message--error {
              margin-left: 2.125rem;
            }
          }
        }

        .dsc-card {
          @include custom-card(0rem, 2rem);
          margin-bottom: 1rem;

          .dsc-heading {
            color: $black;
          }

          .dsc-text {
            margin-top: 1rem;
          }

          .dsc-formGroup {
            margin-top: 1rem;
            margin-bottom: 0;
            .dsc-checkbox .dsc-checkbox__label {
              @include fontWeightAndSize(normal, 0.8675rem);
            }
          }

          ul {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: -2.25rem;

            li {
              list-style: none;
              background: url('../../assets/icons/check/ico-check-black.svg') no-repeat 0px 0.0625rem;
              padding: 0 0 0.125rem 1.25rem;
              font-size: 0.75rem;
              color: $grey600;
            }
          }
        }
        .dsc-radio {
          margin-bottom: 0;
        }
      }
    }

    .dsc-radio__text {
      .dsc-radio__info {
        .long-description.first {
          @include fontWeightAndSize(normal, 0.875rem);
          margin-bottom: 0;
          margin-top: 0.25rem;
        }
      }
    }

    .idv-footer {
      display: flex;
      flex-direction: row-reverse;
    }

    .bank-reference {
      margin: 1rem 0 0rem 2rem;
      .dsc-heading {
        margin-bottom: 0.5rem;
        font-family: $ff-system;
      }
      .dsc-combo {
        margin: 0 !important;
      }
    }

    .bankingAccount {
      background-color: #e2eff8;
      padding: 1.5rem;
      border-radius: 0.25rem;
    }
  }

  & + .layout-footer {
    .footer-wrapper {
      border-top: none;
      padding-top: 0;
    }
  }

  .identity-validation-sign {
    @include unbordered-fw-card(0.75rem);
    opacity: 0.5;
    padding: 1rem 1.5rem;

    .dsb-header {
      display: flex;
      .dsb-title {
        margin: 0 1rem;
        font-family: $ff-gilroy;
      }
    }
  }
}
