@import '../../utilities';

.documents__form {
  p {
    padding: 0;
    margin: 0 0 $s-xsmall 0;
  }

  .explanation-row {
    padding-bottom: 0;
    width: 100%;
  }

  .documents-cash-advance-row {
    margin: 0 $s-normal;
    font-size: $fs-4;

    .section__explanation {
      text-align: justify;
      font-size: $fs-2;
      margin: $s-xsmall $s-xsmall;
      color: $navy600;
    }

    .dsc-checkbox__label {
      display: flex;
      align-items: center;

      .dsc-checkbox__info {
        font-size: $fs-2;
        color: $navy600;
      }
    }
  }

  .pdf-row {
    margin-bottom: $s-normal;
    margin-top: $s-normal;

    .pdf-section {
      position: relative;
      padding: $s-medium 0 $s-medium $s-medium;
      background-color: $navy050;
      margin-bottom: $s-normal;

      .pdf-reader {
        height: 55vh;
        overflow: auto;
      }

      .rectangle {
        position: absolute;
        width: 92%;
        height: 40px;
        pointer-events: none;

        &.top {
          top: 0;
          background-image: linear-gradient(to bottom, $navy050, rgba(255, 255, 255, 0));
        }

        &.bottom {
          bottom: 0.75rem;
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $navy050);
        }
      }
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      .pdf-section {
        padding: 0rem 0rem 1rem 1rem;

        .pdf-viewer {
          padding-right: 1rem;
        }
      }
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      .download-section {
        padding-left: 1rem;
      }
    }
  }

  @media screen and (max-width: $bao_content_mobile_width) {
    .pdf-row {
      margin: 1rem -1rem 0rem -1rem;
    }
  }

  .bill-14-header {
    margin-bottom: $s-normal;
  }

  .bill-14-row-en {
    margin-bottom: $s-normal;
  }

  .lang-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .documents-acceptance-row {
    width: 100%;
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }

  .error {
    padding: $bao_height_padding 0;
  }
}
