@import '../../utilities';

.esign_fail__form {
  text-align: center;
  .dsc-icon {
    color: $cheddar;
    margin-bottom: $s-normal;
  }
  h1.title {
    color: $cheddar;
    margin: 0 auto;
  }
  .help-section {
    border-radius: $s-xsmall;
    border: 1px solid $grey300;
    margin-top: $s-large;
    .row {
      padding: $s-normal 0;
      align-items: center;
      .help-section-content {
        text-align: left;
        padding: 1rem;
      }
      .dsc-icon {
        color: $electric-blue;
      }
    }
  }
}
