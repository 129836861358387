@import '../../../utilities';

.summary__section {
  border-top: solid 1px $grey100;
  margin-top: $s-normal;

  .section__header {
    padding-top: $s-small;

    .section__title,
    .section__icon {
      display: flex;
      margin-bottom: $s-xsmall;
      cursor: pointer;
    }

    .section__icon {
      justify-content: flex-end;

      path {
        fill: unset;
      }

      &:hover {
        .editIcon {
          path {
            fill: $electric-blue;
          }
        }
      }

      .editIcon {
        width: $is-medium;
        height: $is-medium;
      }
    }
  }

  .section__content {
    &.close {
      display: none;
    }

    .field .dsc-label,
    &__field-label,
    .list {
      font-size: 0.75rem;
      font-weight: unset;
      color: $navy600;
      margin-bottom: $s-xsmall;
      line-height: 1rem;
    }

    &__field-value,
    .dsc-text.is-paragraph {
      color: black;
      overflow-wrap: break-word;
      margin-bottom: $s-medium;
      line-height: 1rem;
    }

    .transfer-fhsa-message-row {
      margin-bottom: 1rem;
    }

    // special styles for particular fields
    .list {
      margin-top: 0;
    }

    .transfer-list {
      margin: 0rem 0rem 0.6rem 0rem;
    }

    .tax_residency {
      .tax_noreason {
        padding-top: 1rem;
      }
    }
  }
}
