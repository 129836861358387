.dsc-formGroup {
  .read-only {
    display: flex;
  }
  .d-none {
    display: none;
  }
  .dsc-inputGroup {
    .dsc-inputGroup__prefixSuffix {
      width: 6.75rem !important;
    }
  }
}
