@import '../../utilities';

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $bao_breakpoint_small) {
    flex-direction: column;
    text-align: center;
    .app-version {
      margin-bottom: 0.5rem;
    }
  }
}
