@import 'utilities.scss';

.employment__form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  h3.section-title {
    margin-top: 1rem;
  }

  .row.collection-wrapper {
    .dsc-formGroup,
    .dsc-fieldset {
      margin-bottom: 1rem;
    }
  }

  .not-applicable-checkbox-wrapper {
    display: flex;

    .not-applicable-checkbox {
      align-self: center;
      margin-bottom: 0;
    }
  }  

}
