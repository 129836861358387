@import '../../utilities';

.top-nav {
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  z-index: 9;
  box-sizing: border-box;
  .help {
    display: flex;
    margin-right: 2rem;

    svg {
      height: 2rem;
      width: 2rem;
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      svg {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
      p {
        margin: 0;
        &.label {
          font-size: $fs-3;
        }
        &.phone {
          font-size: $fs-4;
          color: $electric-blue;
        }
      }
    }
  }
  .logo-bnc,
  .nav-buttons {
    display: flex;
    align-items: center;
    .nav-lang-btn {
      margin-right: 1rem;
      padding: 0;
      min-width: 2.5rem;
    }
  }

  .logo-bnc {
    .desktop-logo {
      display: block;
    }

    .mobile-logo {
      display: none;
    }

    @media screen and (max-width: $bao_content_mobile_width) {
      .desktop-logo {
        display: none;
      }
      .mobile-logo {
        display: block;
      }
    }
  }

  .close-button svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.quit-modal {
  .quit-alert {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .quit-explanations {
    color: #000000;
    font-weight: $fw-normal;
    font-family: $ff-system;
    font-size: 1rem;
    text-align: left;

    li.dsc-textList__item::before {
      content: '\2022' !important;
    }

    .quit-main-explanation {
      font-size: 1.125rem;
      margin-bottom: 0.875rem;
    }

    .quit-other-explanation {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
}
