@import '~@nbc-design/tokens/dist/styles/index.scss';
@import '~@nbc-design/tokens/dist/assets/bnc-ui-fonts.css';
@import '~@nbc-design/mastercraft/dist/css/index.css';

/* export variables for Front-end */


/* Page size */
$bao_max_width_content_lg: 1440px;
$bao_aside_width: 224px;

/* Menu transition */
$bao_mobile_nav_transition: transform 0.25s ease-in-out;

/* Modal variables */
$bao_modal_consent_width: 800px;

/* Tabs variables */
$bao_tabs_max-width: 232px;

/* Stack order */
$bao_z_index_very_important: 999;

/* Margin */
$bao_height_margin: 1.5rem;

/* Padding */
$bao_height_padding: 1.5rem;

$bao_sidebar_width: 30%;
$bao_topnav_height: 5.5rem;

$bao_content_max_width: 800px;

$bao_content_mobile_width: 577px;

$bao_breakpoint_small: 767px;

/* Extra small devices (portrait phones) */
$bao_breakpoint_xsmall: 359px;

.sub_title_normale {
  font-size: $fs-5;
  margin-bottom: 2rem;
}

.d-none {
  display: none;
}
