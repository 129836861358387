@import 'utilities.scss';

.new-account-request__form {
  .field-wrapper {
    margin-bottom: 30px;
    .has-error {
      background-color: #fbe7ee;
      border-color: #e41c23;
    }
    .error-field-area {
      color: #e41c23;
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }
  }

  //Validations
  .pwd-with-validation__items {
    margin: 1.625em 0 1.75em 0;
  }

  .pwd-with-validation__item {
    text-align: left;
    margin: 0.75em 0;
    align-items: center;
    display: flex;
  }

  .pwd-with-validation__item .bn-icon {
    /* Overriding translate property to ensure check icon position */
    transform: translateY(0) !important;
    svg {
      transform: scale(0.75);
    }
  }

  .pwd-with-validation__icon-wrapper-item {
    height: 1rem;
    width: 1rem;
    background-color: #d2d2d2;
    margin-right: 1em;
    border-radius: 50%;
    transition: background-color 0.25s ease-in-out;
    display: inline-flex;
  }

  .pwd-with-validation__icon-item {
    margin: 0;
    color: #d2d2d2;
    visibility: hidden;
    transition: color 0.25s ease-in-out;
    transform: none;
  }

  .pwd-with-validation__label-item {
    font-family: $ff-gilroy;
    font-size: 1em;
    color: #565656;
    transition: color 0.25s ease-in-out;
    display: inline-flex;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__icon-wrapper-item {
    background-color: #048037;
    animation: scalebounce 0.75s;
    transform: scale 0.25s ease-in-out;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__icon-item {
    visibility: visible;
    color: #fff;
  }

  .pwd-with-validation__item.pwd-with-validation__item--valid .pwd-with-validation__label-item {
    color: #048037;
  }

  @keyframes scalebounce {
    0% {
      transform: scale(1.02);
    }
    25% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.9);
    }
  }
}
