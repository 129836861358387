@import '../../utilities';
.layout-footer {
  &.row {
    padding-bottom: 0;
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px $grey100;
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
  }

  .back-button svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
