.dsc-radio {
  position: relative;
}
.radio-inline {
  display: flex;
  .dsc-radio {
    margin-bottom: 0;
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.radio-tooltip-inline {
  display: flex;
  margin: 0 0 1rem;
  .dsc-radio {
    margin-bottom: 0;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
