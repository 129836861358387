@import 'utilities.scss';

#personal-information-form {
  .joint-spouse-title,
  .joint-applicant-title {
    font-size: 1rem;
    font-weight: 600;
    color: $eternal-blue;
  }

  .has-mailing-address {
    padding-bottom: $s-xsmall;
  }

  .full-name-row {
    margin-bottom: 1rem;
  }

  .label-info {
    color: $grey600;
  }

  .label-info,
  .label-value {
    line-height: $lh-paragraph;
  }

  .address-container {
    border: 1px solid $grey100;
    margin-top: 1.375rem;
    margin-bottom: $s-small;
    border-left: none;
    border-right: none;
    padding-top: $s-small;
    padding-bottom: $s-small;

    .modify-area {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .dsc-btn {
        min-height: $s-normal;
      }
    }

    .actions-area {
      margin-top: -0.5rem;
      display: flex;
      justify-content: flex-end;

      button.is-small {
        min-height: $s-medium;
        min-width: $s-normal;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .dsc-heading {
    padding-bottom: 1.875rem;
  }
}
