@import '../../utilities';

.modal-component {
  font-family: $ff-gilroy;
  font-weight: $fw-normal;
  text-align: center;

  .dsc-modal__container {
    max-width: 40rem;

    .dsc-modal__header {
      .dsc-modal__title {
        display: flex;
        align-items: center;

        font-family: $ff-gilroy;
        font-size: 1.5rem;
        font-weight: 500;

        & > svg {
          margin-right: $s-medium;
        }
      }

      &.no-border-bottom {
        border-bottom: none;
      }

      &.modal-big-title {
        h2 {
          font-size: 1.75rem;
        }
      }
    }

    .dsc-modal__body {
      min-height: auto;
      margin-top: auto;
    }

    .dsc-modal__footer {
      &.modal-right-button {
        justify-content: flex-end;
      }
    }
  }

  &.calculator-modal {
    .dsc-modal__body {
      margin-top: inherit;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .dsc-modal__footer {
      margin-top: inherit;
    }
  }
}
